import { GetStudentProgressResult } from 'src/api/endpoints/performance/performance.types'
import { HttpMethod } from 'src/@types/modules/api.types'
import { baseApi } from '../../baseApi'

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getProgressData: builder.query<GetStudentProgressResult, null | void>({
            providesTags: ['Performance'],
            query: () => ({
                url: 'performance/getProgressData',
                method: HttpMethod.Get,
                errorMessage: 'Не удалось получить информацию о прогрессе',
            }),
        }),
    }),
})

export const {
    useGetProgressDataQuery,
} = api
